<template>
  <div id="jumpDetail">
    <section class="jumpDetail flex mb40 justify-center">
      <div class="item">
        <div class="issuer">
          <div class="flex align-center mb20">
            <el-image class="logo" fit="cover" :src="createLogo" />
            <div class="text-left ml18">
              <div class="f21">{{ createBy }}</div>
              <div>{{ createTime | dataFormat }}</div>
            </div>
             <div class="backBtn" @click="goBack">返回</div>
            <div class="support flex justify-between" >
              <!-- <div class="mr40">{{ viewCount }}人浏览</div> -->
              <!-- 点赞 -->
            <el-col >
              <div class="el_left">
                <div class="action_groupItem flex" @click="saveBaby(isUp)" style="margin-left:-50%;">
                  <div v-if="!isUp">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-dianzan"></use>
                    </svg>
                  </div>
                  <div v-else>
                    <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                      <use xlink:href="#icon-dianzan_active"></use>
                    </svg>
                  </div>
                  <div class="action_text">{{ likeCount }}</div>
                </div>
              </div>
            </el-col>
              <div class="flex align-center">
                <svg class="icon" aria-hidden="true" @click="copy">
                  <use xlink:href="#icon-fenxiang1"></use>
                </svg>
              </div>
            </div>
          </div>
          <div class="flex align-center justify-between">
            <div class="text-left pl20 f17">
              {{ content }}
            </div>
            <!-- <div class="backBtn" @click="goBack">返回</div> -->
          </div>
        </div>
        <div class="emptyBox" />
        <div class="logo_pic">
          <div class="flex babyEnclosureList flex-wrap">
            <template v-for="(sitem, index) in babyEnclosureList">
              <el-image :src="sitem.url" :class="`sitem_${babyEnclosureList.length}`" :key="index" fit="cover" />
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //点赞
      isUp: false,
      jumpDetail: "",
      id: "",
      content: "",
      babyEnclosureList: [],
      createTime: "",
      viewCount: "",
      likeCount: "",
      createLogo: "",
      createBy: "",
      babyCode:""
    };
  },
  methods: {
    getDetail() {
      this.$api.partyBuild.getDetail({ id: this.id }).then((res) => {
        if (res.data.code === 200) {
          this.content = res.data.data.content;
          this.createLogo = res.data.data.createLogo;
          this.createBy = res.data.data.createBy;
          this.babyEnclosureList = res.data.data.babyEnclosureList;
          this.createTime = res.data.data.createTime;
          this.viewCount = res.data.data.viewCount;
          this.likeCount = res.data.data.likeCount;
          this.babyCode = res.data.data.babyCode;
          this.getBabyCount()
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    //分享
    copy () {
      let invitelink = location.href;
      this.$copyText(invitelink).then(
        res => {
          this.$notify({
          title:'已复制到剪切板了',
          message:'分享给您的好友',
          type:'seccess',
          offset:'400',
          duration:2000
        })
        },
        err => {
          console.log(err) // 同上
        }
      )
    },

     //查询宝宝的点赞数和该用户有没有点赞
    getBabyCount() {
    var getData = {};
    getData.babyCode = this.babyCode;
    getData.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this.$api.partyBuild.queryBabyCount(getData).then((res) => {
      if (res.data.code === 200) {
        this.likeCount = res.data.data.count;
        if (res.data.data.babyCode) {
          this.isUp = true;
        } else {
          this.isUp = false;
        }
      }
    });
    },
    //点赞
    saveBaby(isUp) {
      var getData = {};
      getData.babyCode = this.babyCode;
      getData.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
      this.$api.partyBuild.likeBaby(getData).then((res) => {
        if (res.data.code === 200) {
          // console.log("恭喜你，点赞成功啦！");
           this.$message({
            message: "恭喜你，点赞成功啦!",
            type: "success",
            offset:'400'
          });
        }
      });
      if (isUp) {
        this.isUp = false;
        this.likeCount--;
      } else {
        this.isUp = true;
        this.likeCount++;
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
};
</script>

<style lang="less">

#jumpDetail {
  padding: 30px 50px 0;
}
 .action_groupItem {
    display: flex;
    align-items: center;
  }
.item {
  display: flex;
  flex-direction: column;
  width: 800px;
  // height: 402px;
  padding: 14px 0;
  margin-bottom: 44px;
  border-radius: 5px;
  justify-content: space-between;
  background: #eeeeee;
  .emptyBox {
    height: 10px;
    width: 100%;
    background: white;
  }
  .logo_pic {
    padding: 10px 20px 0;
  }
  img {
    width: 100%;
  }
  .support {
    margin-left: auto;
    .icon {
      font-size: 22px;
    }
     .icon:hover{
     cursor: pointer;
   }
  }
  .issuer {
    padding: 0 20px;
    margin-bottom: 14px;
    .backBtn {
      width: 10%;
      margin-top: -1%;
      margin-left: 50%;
      padding: 3px 15px;
      color: #2c2b2b;
      border-radius: 7px;
      background: #bbbbbb;
    }
    .backBtn:hover{
      cursor: pointer;
    }
    .logo {
      width: 63px;
      height: 63px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .sitem_0 {
    width: 381px;
    height: 173px;
    border-radius: 5px;
  }
  .sitem_1 {
    width: 400px;
    height: 400px;
    border-radius: 5px;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
  }
  .sitem_2 {
    width: 360px;
    height: 360px;
    border-radius: 5px;
    overflow: hidden;
  }
  & .sitem_2:first-child {
    margin-right: 30px;
  }
  .sitem_3 {
    width: 240px;
    height: 240px;
    margin: 6.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  // & .sitem_3:nth-child(2) {
  //   margin: 5px 0;
  // }
  .sitem_4 {
    width: 240px;
    height: 240px;
    margin: 6.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_5 {
    width: 240px;
    height: 240px;
    margin: 6.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_6 {
    width: 240px;
    height: 240px;
    margin: 6.5px;
    border-radius: 5px;
    overflow: hidden;
  }

  .sitem_7 {
    width: 180px;
    height: 180px;
    margin: 4.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_8 {
    width: 180px;
    height: 180px;
    margin: 4.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_9 {
    width: 240px;
    height: 240px;
    margin: 6.5px;
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>
